import React, { useState } from 'react';
import { createSlug } from '../../util/urlHelpers';
import { FormattedMessage } from '../../util/reactIntl';
import { Modal, Button, NamedLink, IconCollection, AuthenticationForm } from '..';
import css from './AlogoliaSearchCard.module.css';

const AlogoliaSearchCard = props => {

  const [handleBagItemInProgress, setHandleBagItemInProgress] = useState(false);

  const { hit, currentUser, updateInProgress, onUpdateProfile, setIsDislikeOpen, isDislikeOpen, handleLikeItems } = props;

  const { title, listingImagesUrl, price, size, objectID, authorId, markForSale, askingPrice } = hit || {};

  const { bagItems = [], likeItems = [] } =
    (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};

  const index = bagItems && bagItems.findIndex(e => e == objectID);
  const likeIndex = likeItems && likeItems.findIndex(e => e == objectID);
  const isOwnListing = !!currentUser?.id && authorId === currentUser?.id?.uuid;

  const handleBagItems = () => {
    if (isOwnListing) {
      return null;
    }
    if (!currentUser?.id) {
      setIsModalOpen(true);
    } else {
      if (index > -1) {
        bagItems.splice(index, 1);
        setHandleBagItemInProgress(true);
      } else {
        bagItems.push(objectID);
        setHandleBagItemInProgress(true);
      }
      const profile = {
        protectedData: {
          bagItems,
        },
      };
      onUpdateProfile(profile);
    }
  };

  return (
    <div className={css.root}>
      <NamedLink
        name="ListingPage"
        params={{ id: objectID, slug: createSlug(title) }}
        className={css.link}
        isSearchPage={false}
        target="_blank"
      >
        <div>
          {listingImagesUrl?.length > 0 ? (
            <div className={css.imageWrapper}>
              {isOwnListing ? <p className={css.onproduct}>Own Product</p> : null}
              <img src={listingImagesUrl[0]?.url} alt="listing-img" />
            </div>
          ) : (
            <div className={css.imageWrapper}>
              <IconCollection icon={'NoImage'} />{' '}
            </div>
          )}
          <p className={css.title}>{title}</p>
        </div>
      </NamedLink>

      <div className={css.emptySpace}></div>
      <div className={css.cardFooter}>
        <div className={css.priceWrapper}>
          <p>{(price?.amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
          {(size || size == 0) && (
            <span>
              <FormattedMessage id="ListingCard.size" values={{ size: size?.label ?? size }} />
            </span>
          )}
        </div>
        {(!isOwnListing && markForSale) && (
          <div className={css.bagButton}>
            {handleBagItemInProgress ? (
              <span>
                <div className={css.loader}></div>
                Loading
              </span>
            ) : index > -1 ? (
              <span
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleBagItems();
                }}
              >
                <IconCollection icon={'IconBagAdded'} />
                <span className={css.desktopText}>
                  <FormattedMessage id="ListingCard.addedToBag" />
                </span>
              </span>
            ) : (
              <span
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleBagItems();
                }}
              >
                <IconCollection icon={'addToBag'} />
                <span className={css.desktopText}>
                  <FormattedMessage id="ListingCard.addToBag" />
                </span>
              </span>
            )}
          </div>
        )}
      </div>
      {!isOwnListing && (
        <div
          onClick={e => {
            e.preventDefault();
            likeIndex > -1 ? handleLikeItems(objectID, isOwnListing) : handleLikeItems(objectID, isOwnListing);
          }}
          className={css.likeIcon}
        >
          {likeIndex > -1 ? (
            <span>
              <IconCollection icon="dislikeIcon" />
            </span>
          ) : (
            <span>
              <IconCollection icon="likeIcon" />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default AlogoliaSearchCard;
