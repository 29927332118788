import React from 'react';
import css from './DotsPayoutPagePanel.module.css';
import { useDispatch } from 'react-redux';
import { savePayoutDetails } from '../../containers/DotsPayoutPage/DotsPayoutPage.duck';
import Button from '../Button/Button';
import { allSteps } from '../../util/types';
import ExternalLink from '../ExternalLink/ExternalLink';
import { FormattedMessage } from 'react-intl';
import IconCollection from '../IconCollection/IconCollection';
const walletLink = process.env.REACT_APP_DOTS_WALLET_URL;
const DotsPayoutPagePanel = props => {
  const { location, history, currentUser, userId, flowData, flowDataInProgress } = props || {};
  const { completed_steps, createdAt, link, steps } = flowData || {};
  const dispatch = useDispatch();
  const initializePayoutDots = async () => {
    const response = await dispatch(savePayoutDetails({ user_id: userId }));
  };

  return (
    <div className={css.dotsPayoutPagePanel}>
      {flowDataInProgress ? (
        <div>
          <FormattedMessage id="DotsPayoutPagePanel.loading" />
        </div>
      ) : (
        <>
        {/* <h4><FormattedMessage id="DotsPayoutPagePanel.heading"/></h4> */}
          {completed_steps && completed_steps.length > 0 ? (
            <div className={css.completedSteps}>
              {allSteps?.map(step => (
                <div key={step} className={css.box}>
                  {completed_steps.includes(step) ? (
                    <span>
                      <IconCollection icon="IconTick" />
                    </span> // Check mark
                  ) : (
                    <span>
                      <IconCollection icon="IconClose" />
                    </span> // Cross mark
                  )}
                  {step}
                </div>
              ))}
              <ExternalLink href={walletLink} className={css.externalLink}>
                <Button>
                  <FormattedMessage id="DotsPayoutPagePanel.managePayoutLinkText" />
                </Button>
              </ExternalLink>
            </div>
          ) : (
            <Button onClick={initializePayoutDots} className={css.onBoardingBtn}>
              <FormattedMessage id="DotsPayoutPagePanel.dotsOnboardingButtonText" />
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default DotsPayoutPagePanel;
