import React, { useState, useEffect } from 'react';
import { bool, func, object, oneOf, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { camelize } from '../../util/string';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { LOGIN, PASSWORD, SIGNUP, TEXT, propTypes } from '../../util/types';
import { isSignupEmailTakenError } from '../../util/errors';
import { login, authenticationInProgress, signup, signupWithIdp } from '../../ducks/auth.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { Heading, IconKeys, Modal } from '../../components';

import TermsAndConditions from '../../containers/AuthenticationPage/TermsAndConditions/TermsAndConditions';
import LoginForm from '../../containers/AuthenticationPage/LoginForm/LoginForm';
import SignupForm from '../../containers/AuthenticationPage/SignupForm/SignupForm';
// We need to get ToS asset and get it rendered for the modal on this page.
import { TermsOfServiceContent } from '../../containers/TermsOfServicePage/TermsOfServicePage';

// We need to get PrivacyPolicy asset and get it rendered for the modal on this page.
import { PrivacyPolicyContent } from '../../containers/PrivacyPolicyPage/PrivacyPolicyPage';

import {
  TOS_ASSET_NAME,
  PRIVACY_POLICY_ASSET_NAME,
} from '../../containers/AuthenticationPage/AuthenticationPage.duck';

import css from './AuthenticationForm.module.css';
import PasswordRecoveryForm from '../../containers/PasswordRecoveryPage/PasswordRecoveryForm/PasswordRecoveryForm';
import {
  clearPasswordRecoveryError,
  recoverPassword,
  retypePasswordRecoveryEmail,
} from '../../containers/PasswordRecoveryPage/PasswordRecoveryPage.duck';
import { verifyDotsUser } from '../../util/api';

// Tabs for SignupForm and LoginForm
export const AuthenticationForms = props => {
  const {
    loginError,
    signupError,
    submitLogin,
    authInProgress,
    submitSignup,
    termsAndConditions,
    handleSignupLoginForm,
    isSignupOpen,
    initialEmail,
    recoveryError,
    recoveryInProgress,
    onChange,
    onSubmitEmail,
  } = props;

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handlePasswordVisibilityToggle = () => {
    setIsPasswordVisible(prevState => !prevState);
  };

  const isPasswordVisibleFunction = (visible, field) =>
    visible ? TEXT : field === PASSWORD ? PASSWORD : TEXT;

  const handleSubmitSignup = values => {
    const { fname, lname, otp, user_Id, ...rest } = values;
    const params = { firstName: fname.trim(), lastName: lname.trim(), user_Id, ...rest };
    verifyDotsUser({ otp, user_Id }).then(() => {
      submitSignup(params);
    });
  };

  const loginErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.loginFailed" />
    </div>
  );

  const signupErrorMessage = (
    <div className={css.error}>
      {isSignupEmailTakenError(signupError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  );

  // eslint-disable-next-line no-confusing-arrow
  const errorMessage = (error, message) => (error ? message : null);
  const loginErrors = errorMessage(loginError, loginErrorMessage);
  const SignupErrors = errorMessage(signupError, signupErrorMessage);

  return (
    <div className={css.content}>
      {loginErrors}
      {SignupErrors}
      {isSignupOpen === LOGIN ? (
        <LoginForm
          className={css.loginForm}
          onSubmit={submitLogin}
          inProgress={authInProgress}
          handleSignupLoginForm={handleSignupLoginForm}
          isPasswordVisibleFunction={isPasswordVisibleFunction}
          isPasswordVisible={isPasswordVisible}
          handlePasswordVisibilityToggle={handlePasswordVisibilityToggle}
        />
      ) : isSignupOpen === SIGNUP ? (
        <SignupForm
          className={css.signupForm}
          onSubmit={handleSubmitSignup}
          inProgress={authInProgress}
          termsAndConditions={termsAndConditions}
          isPasswordVisibleFunction={isPasswordVisibleFunction}
          isPasswordVisible={isPasswordVisible}
          handlePasswordVisibilityToggle={handlePasswordVisibilityToggle}
          handleSignupLoginForm={handleSignupLoginForm}
        />
      ) : (
        <div className={css.submitEmailContent}>
          <IconKeys className={css.modalIcon} />
          <Heading as="h1" rootClassName={css.modalTitle}>
            <FormattedMessage id="PasswordRecoveryPage.forgotPasswordTitle" />
          </Heading>
          <p className={css.modalMessage}>
            <FormattedMessage id="PasswordRecoveryPage.forgotPasswordMessage" />
          </p>
          <PasswordRecoveryForm
            inProgress={recoveryInProgress}
            onChange={onChange}
            onSubmit={values => onSubmitEmail(values.email)}
            initialValues={{ email: initialEmail }}
            recoveryError={recoveryError}
          />
        </div>
      )}
    </div>
  );
};

export const AuthenticationFormComponent = props => {
  const {
    authInProgress,
    intl,
    loginError,
    signupError,
    submitLogin,
    submitSignup,
    onManageDisableScrolling,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
    initialEmail,
    submittedEmail,
    recoveryError,
    recoveryInProgress,
    passwordRequested,
    onChange,
    onSubmitEmail,
    onRetypeEmail,
    isModalOpen,
  } = props;

  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(LOGIN);

  useEffect(() => {
    setIsSignupOpen(LOGIN);
  }, [!isModalOpen]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tosModalOpen, privacyModalOpen]);

  const handleSignupLoginForm = value => {
    setIsSignupOpen(value);
  };

  return (
    <div>
      <AuthenticationForms
        loginError={loginError}
        signupError={signupError}
        submitLogin={submitLogin}
        authInProgress={authInProgress}
        submitSignup={submitSignup}
        handleSignupLoginForm={handleSignupLoginForm}
        termsAndConditions={
          <TermsAndConditions
            onOpenTermsOfService={() => setTosModalOpen(true)}
            onOpenPrivacyPolicy={() => setPrivacyModalOpen(true)}
            intl={intl}
          />
        }
        isSignupOpen={isSignupOpen}
        initialEmail={initialEmail}
        submittedEmail={submittedEmail}
        recoveryError={recoveryError}
        recoveryInProgress={recoveryInProgress}
        passwordRequested={passwordRequested}
        onChange={onChange}
        onSubmitEmail={onSubmitEmail}
        onRetypeEmail={onRetypeEmail}
      ></AuthenticationForms>
      <Modal
        id="AuthenticationPage.tos"
        isOpen={tosModalOpen}
        onClose={() => setTosModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.termsWrapper}>
          <TermsOfServiceContent
            inProgress={tosFetchInProgress}
            error={tosFetchError}
            data={tosAssetsData?.[camelize(TOS_ASSET_NAME)]?.data}
          />
        </div>
      </Modal>
      <Modal
        id="AuthenticationPage.privacyPolicy"
        isOpen={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.privacyWrapper}>
          <PrivacyPolicyContent
            inProgress={tosFetchInProgress}
            error={tosFetchError}
            data={tosAssetsData?.[camelize(PRIVACY_POLICY_ASSET_NAME)]?.data}
          />
        </div>
      </Modal>
    </div>
  );
};

AuthenticationFormComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  confirmError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
  showSocialLoginsForTests: false,
  privacyAssetsData: null,
  privacyFetchInProgress: false,
  privacyFetchError: null,
  tosAssetsData: null,
  tosFetchInProgress: false,
  tosFetchError: null,
};

AuthenticationFormComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  confirmError: propTypes.error,

  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  tab: oneOf(['login', 'signup', 'confirm']),

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // to fetch privacy-policy page asset
  // which is shown in modal
  privacyAssetsData: object,
  privacyFetchInProgress: bool,
  privacyFetchError: propTypes.error,

  // to fetch terms-of-service page asset
  // which is shown in modal
  tosAssetsData: object,
  tosFetchInProgress: bool,
  tosFetchError: propTypes.error,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, confirmError } = state.auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const {
    pageAssetsData: privacyAssetsData,
    inProgress: privacyFetchInProgress,
    error: privacyFetchError,
  } = state.hostedAssets || {};
  const { pageAssetsData: tosAssetsData, inProgress: tosFetchInProgress, error: tosFetchError } =
    state.hostedAssets || {};
  const {
    initialEmail,
    submittedEmail,
    recoveryError,
    recoveryInProgress,
    passwordRequested,
  } = state.PasswordRecoveryPage;
  return {
    initialEmail,
    submittedEmail,
    recoveryError,
    recoveryInProgress,
    passwordRequested,
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    privacyAssetsData,
    privacyFetchInProgress,
    privacyFetchError,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
  submitSingupWithIdp: params => dispatch(signupWithIdp(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onChange: () => dispatch(clearPasswordRecoveryError()),
  onSubmitEmail: email => dispatch(recoverPassword(email)),
  onRetypeEmail: () => dispatch(retypePasswordRecoveryEmail()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationForm = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AuthenticationFormComponent);

export default AuthenticationForm;
