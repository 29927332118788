import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconClose.module.css';
const SIZE_SMALL = 'small';

const IconClose = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (size === SIZE_SMALL) {
    return (
      <svg className={classes} width="9" height="9" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.175 8.396l2.482-2.482 2.482 2.482a.889.889 0 1 0 1.258-1.257L5.914 4.657l2.482-2.483A.89.89 0 0 0 7.139.917L4.657 3.4 2.175.918A.888.888 0 1 0 .917 2.174L3.4 4.657.918 7.139a.889.889 0 1 0 1.257 1.257"
          fillRule="evenodd"
        />
      </svg>
    );
  }

  return (
    <svg
      className={classes}
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.1003 22.0151C24.1874 22.0993 24.2565 22.1992 24.3037 22.3092C24.3508 22.4193 24.3751 22.5372 24.3751 22.6562C24.3751 22.7753 24.3508 22.8932 24.3037 23.0032C24.2565 23.1132 24.1874 23.2132 24.1003 23.2974C24.0132 23.3816 23.9098 23.4484 23.796 23.494C23.6822 23.5395 23.5602 23.563 23.437 23.563C23.3139 23.563 23.1919 23.5395 23.0781 23.494C22.9643 23.4484 22.8609 23.3816 22.7738 23.2974L14.9995 15.7812L7.22533 23.2974C7.04941 23.4675 6.81082 23.563 6.56204 23.563C6.31327 23.563 6.07468 23.4675 5.89876 23.2974C5.72285 23.1274 5.62402 22.8967 5.62402 22.6562C5.62402 22.4158 5.72285 22.1851 5.89876 22.0151L13.6742 14.5L5.89876 6.98491C5.72285 6.81486 5.62402 6.58422 5.62402 6.34374C5.62402 6.10325 5.72285 5.87261 5.89876 5.70256C6.07468 5.53251 6.31327 5.43698 6.56204 5.43698C6.81082 5.43698 7.04941 5.53251 7.22533 5.70256L14.9995 13.2188L22.7738 5.70256C22.9497 5.53251 23.1883 5.43698 23.437 5.43698C23.6858 5.43698 23.9244 5.53251 24.1003 5.70256C24.2762 5.87261 24.3751 6.10325 24.3751 6.34374C24.3751 6.58422 24.2762 6.81486 24.1003 6.98491L16.3249 14.5L24.1003 22.0151Z"
        fill="black"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconClose.defaultProps = {
  className: null,
  rootClassName: null,
};

IconClose.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconClose;
