import { transitions } from '../../transactions/transactionDotsProcessPurchase';
import { deleteAlgoliaData, queryListings, updateAlgoliaData } from '../../util/api';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { updateStockOfListingMaybe } from '../EditListingPage/EditListingPage.duck';
const { UUID } = sdkTypes;
// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/ManageListingsPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/ManageListingsPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManageListingsPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ManageListingsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ManageListingsPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManageListingsPage/CLOSE_LISTING_ERROR';

export const DELETE_LISTING_REQUEST = 'app/ManageListingsPage/DELETE_LISTING_REQUEST';
export const DELETE_LISTING_SUCCESS = 'app/ManageListingsPage/DELETE_LISTING_SUCCESS';
export const DELETE_LISTING_ERROR = 'app/ManageListingsPage/DELETE_LISTING_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageListingsPage/ADD_OWN_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  deleteDraftInProgress: false,
  offerTransactions: [],
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const ownListingEntities = {
    ...state.ownEntities.ownListing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
  };
};

const manageListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.response.data),
        pagination: payload.response.data.meta,
        queryInProgress: false,
        offerTransactions: payload.listingsTransactionArray,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...state,
        closingListing: null,
        closingListingError: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case DELETE_LISTING_REQUEST:
      return {
        ...state,
        deleteDraftInProgress: true,
      };
    case DELETE_LISTING_SUCCESS:
      return {
        ...state,
        deleteDraftInProgress: false,
      };
    case DELETE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        deleteDraftInProgress: false,
      };
    }

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    default:
      return state;
  }
};

export default manageListingsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
// export const getOwnListingsById = (state, listingIds) => {
//   const { ownEntities } = state.ManageListingsPage;
//   const resources = listingIds.map(id => ({
//     id,
//     type: 'ownListing',
//   }));
//   const throwIfNotFound = false;
//   return denormalisedEntities(ownEntities, resources, throwIfNotFound);
// };

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  // payload: response,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const deleteListingRequest = () => ({
  type: DELETE_LISTING_REQUEST,
});

export const deleteListingSuccess = () => ({
  type: DELETE_LISTING_SUCCESS,
});

export const deleteListingError = e => ({
  type: DELETE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = (response, listingsTransactionArray) => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { response, listingsTransactionArray },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const getTransactions = async (listingId, sdk) => {
  try {
    const res = await sdk.transactions.query({
      only: 'sale',
      lastTransitions: [transitions.MAKE_AN_OFFER_BY_CUSTOMER],
      listingId,
      include: ['listing'],
    });
    if (res?.data?.data?.length) {
      return res?.data?.data;
    }
  } catch (err) {
    console.log('err :>> ', err);
  }
};

const getImageVariantInfo = listingImageConfig => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  const fieldsImage = [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`];

  return {
    fieldsImage,
    imageVariants: {
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    },
  };
};

// export const getProperResponseOfListing = (response, config) => (dispatch, getState, sdk) =>{
//   const responseEntries = response.data.data.length ? updatedEntities({}, response.data, { listingFields: config?.listing?.listingFields }) : {};
//   const resources = response.data.data.length ? response.data.data.map((st) => ({ id: st.id, type: "listing" })) : [];
//   const responseArray = response.data.data.length ? denormalisedEntities(responseEntries, resources, false) : [];
//   const newPriceArray = responseArray?.length && responseArray.map((e)=>{
//     const price = e?.attributes?.price?.amount ? new Money(e.attributes.price.amount,e.attributes.price.currency) : null;
//     return  {...e , attributes: {...e.attributes , price:price } };
//   })
// dispatch(queryListingsSuccess(newPriceArray));
// }

// Throwing error for new (loadData may need that info)
export const queryOwnListings = (queryParams, config) => async (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));
  let { currentUser } = getState().user;
  if (!currentUser || (currentUser && !currentUser.id)) {
    currentUser = await dispatch(fetchCurrentUser());
  }
  const userId = currentUser?.id?.uuid;
  const { perPage, ...rest } = queryParams;
  const params = {
    ...rest,
    authorId: userId,
    perPage,
    states: ['published', 'draft', 'pendingApproval'],
  };

  try {
    const response = userId && (await queryListings(params));
    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };

    const listingsTransactionArray = [];
    await Promise.all(
      response?.data?.data?.map(async item => {
        const transactions = await getTransactions(item?.id?.uuid, sdk);
        if (transactions?.length) {
          listingsTransactionArray.push(...transactions);
        }
      })
    );

    dispatch(addMarketplaceEntities(response, sanitizeConfig));
    dispatch(queryListingsSuccess(response, listingsTransactionArray));
    return response;
  } catch (e) {
    dispatch(queryListingsError(storableError(e)));
    throw e;
  }
};

export const discardDraftListing = (listingId, config) => async (dispatch, getState, sdk) => {
  try {
    dispatch(deleteListingRequest());
    const result = await sdk.ownListings.discardDraft({
      id: listingId,
    });

    if (result) {
      dispatch(deleteListingSuccess());
      const {
        aspectWidth = 1,
        aspectHeight = 1,
        variantPrefix = 'listing-card',
      } = config.layout.listingImage;
      const aspectRatio = aspectHeight / aspectWidth;
      const queryParam = {
        page: 1,
        perPage: RESULT_PAGE_SIZE,
        include: ['images', 'currentStock'],
        'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1,
      };
      dispatch(queryOwnListings(queryParam, config));
      return result;
    }
  } catch (e) {
    dispatch(deleteListingError(storableError(e)));
  }
};

export const closeListing = (listingId, config) => (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;
  const queryParam = {
    page: 1,
    perPage: RESULT_PAGE_SIZE,
    include: ['images', 'currentStock'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  };
  dispatch(closeListingRequest(listingId));
  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(closeListingSuccess());
      if (response) {
        dispatch(queryOwnListings(queryParam, config));
      }
      return response;
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));
    });
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));
  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(openListingError(storableError(e)));
    });
};

const createFileFromImageData = async (imageData, config, sdk) => {
  const response = await fetch(imageData.url);
  const blob = await response.blob();
  const file = new File([blob], imageData.fileName, {
    type: imageData.type,
    lastModified: new Date().getTime(),
  });
  if (file) {
    const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);
    const queryParams = {
      expand: true,
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };
    const result = await sdk.images.upload({ image: file }, queryParams);
    const img = result.data.data;
    return img;
  }
};

// Return an array of image ids
const imageIds = images => {
  // For newly uploaded image the UUID can be found from "img.imageId"
  // and for existing listing images the id is "img.id"
  return images ? images.map(img => img.imageId || img.id) : null;
};

const getImages = async (listingImagesUrl, config, sdk) => {
  const filePromises = listingImagesUrl.map(imageData =>
    createFileFromImageData(imageData, config, sdk)
  );
  const files = await Promise.all(filePromises);
  return files;
};

export const copyListing = (listing, config, stockUpdateMaybe) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(queryListingsRequest({}));
  const { listingImagesUrl } = listing?.publicData || {};

  try {
    const images = await getImages(listingImagesUrl, config, sdk);
    const imageProperty = images ? { images: imageIds(images) } : {};
    const {
      aspectWidth = 1,
      aspectHeight = 1,
      variantPrefix = 'listing-card',
    } = config.layout.listingImage;
    const aspectRatio = aspectHeight / aspectWidth;
    const queryParam = {
      page: 1,
      perPage: RESULT_PAGE_SIZE,
      include: ['images', 'currentStock'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    };

    // Create the listing
    const response = await sdk.ownListings.create(
      { ...listing, ...imageProperty },
      { expand: true }
    );

    if (response) {
      const listingId = response.data.data.id;
      // If stock update is necessary, update the stock
      if (stockUpdateMaybe?.stockUpdate) {
        await updateStockOfListingMaybe(listingId, stockUpdateMaybe.stockUpdate, dispatch);
      }

      // Fetch the updated listings
      await dispatch(queryOwnListings(queryParam, config));
    }
    return response.data.data;
  } catch (e) {
    console.error(e);
    // Handle the error appropriately
  }
};

export const markingForSale = (listing, config) => (dispatch, getState, sdk) => {
  return sdk.ownListings
    .update(listing, { expand: true })
    .then(response => {
      const { publicData = {} } = response.data.data.attributes;
      const { markForSale, } = publicData || {};
      updateAlgoliaData({
        indexName: process.env.REACT_APP_ALGOLIA_LISTING_INDEX,
        objectID: response.data.data.id.uuid,
        markForSale,
      });
      return response;
    })
    .catch(e => {});
};

export const loadData = (params, search, config) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;
  return queryOwnListings(
    {
      ...queryParams,
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['images', 'currentStock'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    },
    config
  );
};
