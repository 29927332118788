import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';

const UserNav = props => {
  const { className, rootClassName, currentPage } = props;
  const classes = classNames(rootClassName || css.root, className);

  const tabs = [
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
      width:130,
    },
    {
      text: <FormattedMessage id="UserNav.PasswordChangePage" />,
      selected: currentPage === 'PasswordChangePage',
      disabled: false,
      linkProps: {
        name: 'PasswordChangePage',
      },
      width:130,
    },
    {
      text: <FormattedMessage id="UserNav.Payouts" />,
      selected: currentPage === 'DotsPayoutPage',
      disabled: false,
      linkProps: {
        name: 'DotsPayoutPage',
      },
      width:170,
    },
    {
      text: <FormattedMessage id="UserNav.DotsPayment" />,
      selected: currentPage === 'PaymentMethodsPage',
      disabled: false,
      linkProps: {
        name: 'PaymentMethodsPage',
      },
      width:170,
    },
 
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: currentPage === 'ContactDetailsPage',
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
      width:130,
    },
    
  ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
