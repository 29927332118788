import React from 'react';
import { loadDots } from '@dots.dev/dots-js';
import { Elements } from '@dots.dev/react-dots-js';
import { retrieveDotsClientId } from '../../util/api';
import DotsPaymentMethodForm from '../../containers/DotsPayoutPage/DotsPaymentMethodForm/DotsPaymentMethodForm';
import { IconSpinner } from '..';

const CheckoutPaymentPanel = props => {
  async function getDots() {
    try {
      const { clientId } = await retrieveDotsClientId();
      const dots = await loadDots(clientId, process.env.REACT_APP_DOTS_MODE);
      return dots;
    } catch (error) {
      // Handle any errors that occur during the process
      console.error('Error loading Dots:', error);
      throw error; // Rethrow the error to propagate it to the caller
    }
  }
  const {
    currentUser,
    deleteListingStock,
    selectMethod,
    transaction,
    dotsPaymentIntent,
    dispatch,
    isListingPage,
    onOfferModalOpen,
    isPaymentMethod,
    onUpdateProfile,
    orderNumber,
    onAddOrderNumber,
    isCheckoutPage,
    dotsPromises,
    handleSavedMethods,
    handlePaymentMethod,
    handleWalletBalance,
    addBalance,
    handleRates,
  } = props || {};

  const dotsPromise = !isCheckoutPage && getDots(); // This will return a Promise
  return (
    <>
      {isCheckoutPage ? (
        <div>
          {dotsPromises ? (
            <DotsPaymentMethodForm
              currentUser={currentUser}
              selectMethod={selectMethod}
              transaction={transaction}
              dispatch={dispatch}
              dotsPaymentIntent={dotsPaymentIntent}
              isListingPage={isListingPage}
              onOfferModalOpen={onOfferModalOpen}
              isPaymentMethod={isPaymentMethod}
              onUpdateProfile={onUpdateProfile}
              deleteListingStock={deleteListingStock}
              orderNumber={orderNumber}
              onAddOrderNumber={onAddOrderNumber}
              isCheckoutPage={isCheckoutPage}
              handleSavedMethods={handleSavedMethods}
              handlePaymentMethod={handlePaymentMethod}
              handleWalletBalance={() => { }}
              addBalance={addBalance}
              handleRates={() => { }}
            />
          ) : (
            <div>
              <IconSpinner />
            </div>
          )}
        </div>
      ) : (
        <div>
          {dotsPromise ? (
            <Elements dots={dotsPromise}>
              <DotsPaymentMethodForm
                currentUser={currentUser}
                selectMethod={selectMethod}
                transaction={transaction}
                dispatch={dispatch}
                dotsPaymentIntent={dotsPaymentIntent}
                isListingPage={isListingPage}
                onOfferModalOpen={onOfferModalOpen}
                isPaymentMethod={isPaymentMethod}
                onUpdateProfile={onUpdateProfile}
                deleteListingStock={deleteListingStock}
                orderNumber={orderNumber}
                onAddOrderNumber={onAddOrderNumber}
                isCheckoutPage={isCheckoutPage}
                handleSavedMethods={() => { }}
                handlePaymentMethod={() => { }}
                handleWalletBalance={handleWalletBalance}
                addBalance={addBalance}
                handleRates={handleRates}
              />
            </Elements>
          ) : (
            <div>
              <IconSpinner />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CheckoutPaymentPanel;
