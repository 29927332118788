import React, { useEffect, useMemo, useState } from 'react';

import { PaymentElement, useDots, useElements } from '@dots.dev/react-dots-js';
import css from './DotsPaymentMethodForm.module.css';
import countryList from 'react-select-country-list';
import { useForm } from 'react-hook-form';
import {
  attachPaymentMethod,
  confirmPaymentIntent,
  createAchPayments,
  createPaymentCustomer,
  createPaymentIntent,
  deleteAlgoliaData,
  retrieveDotsUser,
  retrivePaymentMethod,
  updateListingWithTransaction,
} from '../../../util/api';
import { getUserDetails } from '../../../util/dataExtractor';
import Base64 from 'base-64';
import { Button, IconCollection } from '../../../components';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../../components/IconSpinner/IconSpinner';
import IconCard from '../../../components/SavedCardDetails/IconCard/IconCard';
import { ACH_METHOD, CARD_METHOD } from '../../../util/types';
import { transitions } from '../../../transactions/transactionDotsProcessPurchase';
import { pathByRouteName } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { makeTransitions } from '../../TransactionPage/TransactionPage.duck';

const indexName = process.env.REACT_APP_ALGOLIA_LISTING_INDEX;

export default function DotsPaymentMethodForm(props) {
  const routeConfiguration = useRouteConfiguration();
  const history = useHistory();
  const {
    currentUser,
    selectMethod,
    transaction,
    dispatch,
    isListingPage,
    isPaymentMethod,
    onUpdateProfile,
    deleteListingStock,
    orderNumber,
    onAddOrderNumber,
    isCheckoutPage,
    handleSavedMethods,
    handlePaymentMethod,
    handleWalletBalance,
    addBalance,
  } = props || {};

  const { listing } = transaction || {};
  const [error, setError] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [formFetching, setFormFetching] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [savePaymentMethod, setSavePaymentMethod] = useState(false);
  const [editPaymentMethod, setEditPaymentMethod] = useState(false);
  const [fecthDetailsInProgress, setFecthDetailsInProgress] = useState(false);
  const [savedCardData, setSavedCardData] = useState(null);
  const [dotsUserData, setDotsUserData] = useState(null);
  const { amount } = (!!transaction?.id && transaction.attributes.payinTotal) || {};
  const { uuid } = (!!transaction?.id && transaction.provider.id) || {};
  const { cartData } = (!!transaction?.id && transaction.attributes.protectedData) || {};
  const { customerId } = (!!currentUser?.id && currentUser.attributes.profile.privateData) || {};
  const { postalCode, country } = currentUser?.attributes?.profile?.protectedData?.location || {};
  const { bagItems = [] } =
    (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};

  const handleTransaction = async ({ payment_method_id, payment_intent_id }) => {
    try {
      const res = await confirmPaymentIntent({ payment_intent_id, payment_method_id });

      if (res) {
        const transitionParams = {
          txId: transaction.id,
          transitionName: transitions.CONFIRM_PAYMENT,
          params: {
            protectedData: {
              payment_method_id,
              payment_intent_id,
              transfer_id: [res?.transfer_id],
              orderNumber,
            },
          },
        };

        const transitionData = await dispatch(makeTransitions(transitionParams));

        if (transitionData) {
          const id = listing.id.uuid;
          if (cartData?.length > 0) {
            const updatedBagItems =
              cartData?.length && bagItems?.length
                ? bagItems.filter(item => !cartData.some(cartItem => cartItem.listingId === item))
                : [];
            onUpdateProfile({
              protectedData: {
                bagItems: updatedBagItems,
              },
            });
            deleteListingStock();
            await Promise.all(
              cartData.map(async element => {
                await updateListingWithTransaction({
                  listingId: element?.listingId,
                  transactionId: transaction.id.uuid,
                });
                await deleteAlgoliaData({
                  ID: element?.listingId,
                  indexName: indexName,
                });
              })
            );
          } else {
            await updateListingWithTransaction({
              listingId: id,
              transactionId: transaction.id.uuid,
            });
            await deleteAlgoliaData({
              ID: id,
              indexName: indexName,
            });
          }
          setProcessing(false);
          onAddOrderNumber(orderNumber);
          const orderDetailsPath = pathByRouteName('OrderDetailsPage', routeConfiguration, {
            id: transaction.id.uuid,
          });

          history.push(orderDetailsPath);
        }
      }
    } catch (error) {
      console.error('Error in handleTransaction:', error);
    }
  };

  const loadDotCard = async () => {
    try {
      setFecthDetailsInProgress(true);
      const result = await retrivePaymentMethod();
      if (result) {
        setSavedCardData(result?.card);
        setFecthDetailsInProgress(false);
      }
    } catch (error) {
      setFecthDetailsInProgress(false);
    }
  };

  const fetchDotsUser = () => {
    currentUser?.id &&
      retrieveDotsUser({ providerId: currentUser?.id?.uuid }).then(res => {
        setDotsUserData(res);
      });
  };

  useEffect(() => {
    fetchDotsUser();
  }, [!!currentUser?.id]);

  useEffect(() => {
    // Call the functions
    loadDotCard();
    setFormFetching(true);

    // Set a timeout to stop fetching after 5 seconds
    const timeoutId = setTimeout(() => {
      setFormFetching(false);
    }, 5000);

    // Cleanup function
    return () => {
      // Clear the timeout if the component unmounts or dependencies change
      clearTimeout(timeoutId);
    };
  }, []);

  const dots = useDots();
  const elements = useElements();
  const countryOptions = useMemo(() => countryList().getData(), []);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onOfferSubmit = async data => {
    if (!dots || !elements) return;
    setProcessing(true);
    try {
      const userDetails = getUserDetails(currentUser);
      if (!userDetails) return null;

      const { firstName, lastName } = userDetails;
      const paymentMethod = await dots.addPaymentMethod({
        payment_method: {
          element: elements.getElement('payment'),
          billing_details: {
            name: firstName + lastName,
            address: { country, zip: postalCode },
          },
        },
      });
      const paymentCustomer = await createOrUpdatePaymentCustomer(currentUser, 'US');
      if (savePaymentMethod) {
        paymentCustomer &&
          (await attachPaymentMethod({
            paymentMethodId: paymentMethod?.id,
            customerId: paymentCustomer.id,
          }));
        setEditPaymentMethod(false);
        const result = await retrivePaymentMethod();
        if (result) {
          setSavedCardData(result?.card);
        }
      }
    } catch (error) {
      setError(error.toString());
    } finally {
      setProcessing(false);
    }
  };

  const onSubmit = async data => {
    if (!dots || !elements) return;
    setProcessing(true);

    try {
      const response = await createPaymentIntent({
        amount, // Ensure `amount` is defined and valid
        providerId: uuid,
      });

      if (response) {
        const paymentMethod = await dots.addPaymentMethod({
          payment_method: {
            element: elements.getElement('payment'),
            billing_details: {
              name: data.name,
              address: { country: data.country, zip: data.zip },
            },
          },
        });
        if (savePaymentMethod) {
          await savePaymentMethodToCustomer(paymentMethod.id, data.country, response?.id);
        } else {
          const paymentCustomer = await createOrUpdatePaymentCustomer(currentUser, data.country);
          if (paymentCustomer) {
            await handleTransaction({
              payment_method_id: paymentMethod.id,
              payment_intent_id: response?.id,
            });
          }
        }
        setSucceeded(true);
      }
    } catch (error) {
      setError(error.toString());
    } finally {
      setProcessing(false);
    }
  };

  const savePaymentMethodToCustomer = async (paymentMethodId, country, payment_intent_id) => {
    const paymentCustomer = await createOrUpdatePaymentCustomer(currentUser, country);
    if (paymentCustomer) {
      const data = await attachPaymentMethod({ paymentMethodId, customerId: paymentCustomer.id });
      if (data) {
        await handleTransaction({
          payment_method_id: paymentMethodId,
          payment_intent_id,
        });
      }
    }
  };

  const createOrUpdatePaymentCustomer = async (currentUser, country) => {
    const userDetails = getUserDetails(currentUser);
    if (!userDetails) return null;

    const { email, firstName, lastName, id } = userDetails;
    const paymentCustomer = await createPaymentCustomer({
      country_code: country,
      email,
      first_name: firstName,
      last_name: lastName,
      id,
    });

    return paymentCustomer;
  };

  const onAchSubmit = async data => {
    if (!dots || !elements) return;
    setProcessing(true);

    try {
      let paymentCustomerId = customerId;

      if (!paymentCustomerId) {
        const paymentCustomer = await createOrUpdatePaymentCustomer(currentUser, 'US');
        if (!paymentCustomer) {
          throw new Error('Failed to create or update payment customer');
        }
        paymentCustomerId = paymentCustomer.id;
      }

      const result = await createAchPayments({
        amount,
        account_number: data?.accountNumber,
        routing_number: data?.routingNumber,
        account_type: data?.accountType,
        customer_id: paymentCustomerId,
      });
    } catch (error) {
      setError(error.toString());
    } finally {
      setProcessing(false);
    }
  };

  const renderForm = () => {
    const fieldOptions = {
      styles: {
        base: {
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          color: '#32325d',
          fontWeight: '400',
          fontSize: '16px',
          colorPrimaryText: '#32325d',
          colorIcon: '#32325d',
        },
        invalid: {
          ':hover': {
            textDecoration: 'underline dotted red',
          },
          color: '#fa755a',
        },
        valid: {
          color: '#32CD32',
        },
      },
    };

    return (
      <form
        onSubmit={
          isListingPage || isPaymentMethod ? handleSubmit(onOfferSubmit) : handleSubmit(onSubmit)
        }
        className={css.formRoot}
      >
        {(!isListingPage || isPaymentMethod) && (
          <div
            onClick={() => {
              setEditPaymentMethod(false);
              handlePaymentMethod();
            }}
            className={css.iconClose}
          >
            <IconCollection icon={'crossIcon'} />
          </div>
        )}
        <PaymentElement options={fieldOptions} />

        {error && <div className="message sr-field-error">{error}</div>}
        <div>
          <span
            onClick={() => {
              if (savePaymentMethod) {
                setSavePaymentMethod(false);
              } else {
                setSavePaymentMethod(true);
              }
              handleSavedMethods();
            }}
            className={css.checkboxs}
          >
            {savePaymentMethod ? (
              <IconCollection icon="checkIcon" />
            ) : (
              <IconCollection icon="uncheckIcon" />
            )}{' '}
            <FormattedMessage id="DotsPaymentMethodForm.saveText" />
          </span>
        </div>
        <br />
        {isListingPage && (
          <div className={css.applyRemaining}>
            <span>
              <FormattedMessage
                id="ListingsPage.walletText"
                values={{ balance: dotsUserData?.wallet?.amount / 100 || 0 }}
              />{' '}
            </span>{' '}
            {dotsUserData?.wallet?.amount !== 0 && (
              <div className={css.radioBoxes}>
                <span
                  onClick={() => {
                    handleWalletBalance('yes');
                  }}
                  className={css.radioBox}
                >
                  <IconCollection icon={addBalance === 'yes' ? 'yesIcon' : 'noIcon'} />
                  <FormattedMessage id="ListingsPage.yes" />
                </span>{' '}
                <span
                  onClick={() => {
                    handleWalletBalance('no');
                  }}
                  className={css.radioBox}
                >
                  <IconCollection icon={addBalance === 'no' ? 'yesIcon' : 'noIcon'} />
                  <FormattedMessage id="ListingsPage.no" />
                </span>
              </div>
            )}
          </div>
        )}
        <br />
        {isListingPage || isPaymentMethod ? (
          <Button
            inProgress={processing}
            disabled={processing || !savePaymentMethod}
            className={
              isListingPage
                ? classNames(css.fieldBtn, css.fieldBtnCenter)
                : classNames(css.fieldBtn, css.fieldBtnLeft)
            }
          >
            {processing ? (
              'Processing…'
            ) : isListingPage ? (
              <FormattedMessage id="CheckoutPaymentPage.confirm" />
            ) : (
              <FormattedMessage id="CheckoutPaymentPage.addCard" />
            )}
          </Button>
        ) : isCheckoutPage ? null : (
          <Button
            inProgress={processing}
            disabled={processing || !savePaymentMethod}
            className={css.fieldBtn}
          >
            {processing ? 'Processing…' : <FormattedMessage id="CheckoutPaymentPage.pay" />}
          </Button>
        )}
      </form>
    );
  };

  const renderAchForm = () => {
    return (
      <form onSubmit={handleSubmit(onAchSubmit)}>
        <div className="sr-combo-inputs">
          <div className="sr-combo-inputs-row">
            <label>Account Number</label>
            <input
              type="text"
              placeholder="Account Number"
              className="sr-input"
              {...register('accountNumber', { required: true })}
            />
            <label>Routing Number</label>
            <input
              type="text"
              placeholder="Routing Number"
              className="sr-input"
              {...register('routingNumber', { required: true })}
            />
            <label>Account Type</label>
            <select
              defaultValue="US"
              className="sr-select"
              {...register('accountType', { required: true })}
            >
              <option value={''}>Select One</option>
              <option value={'savings'}>Savings</option>
              <option value={'checking'}>Checking</option>
            </select>
          </div>
        </div>

        {error && <div className="message sr-field-error">{error}</div>}
        <Button disabled={processing} className={css.fieldBtn}>
          {processing ? 'Processing…' : <FormattedMessage id="CheckoutPaymentPage.pay" />}
        </Button>
      </form>
    );
  };

  if (fecthDetailsInProgress) {
    return <Spinner />;
  }

  const paymentMethodPlaceholderDesktop = (
    <FormattedMessage
      id="SavedCardDetails.savedPaymentMethodPlaceholderDesktop"
      values={{ last4Digits: savedCardData?.last4 }}
    />
  );
  return (
    <>
      {selectMethod === ACH_METHOD && <div>{renderAchForm()}</div>}
      {selectMethod === CARD_METHOD && (
        <div className="checkout-form">
          <div className="sr-payment-form">
            <div className="sr-form-row" />
            {(savedCardData || customerId) && !editPaymentMethod ? (
              <div>
                <div className={css.savedCard}>
                  {isListingPage ? <b>Payment: </b> : <span className={css.customChecked}></span>}
                  <IconCard brand={savedCardData?.brand} />
                  <span>
                    {paymentMethodPlaceholderDesktop} {savedCardData?.exp_month}/
                    {savedCardData?.exp_year}
                  </span>{' '}
                </div>
                <br />
                {!isListingPage && (
                  <>
                    <div
                      onClick={() => {
                        setEditPaymentMethod(true);
                        handlePaymentMethod();
                      }}
                      className={css.savedCardEnd}
                    >
                      <span className={css.customChecks}></span>
                      <div>
                        <span className={css.textSm}>
                          <FormattedMessage id="CheckoutPaymentPage.useOtherText" />
                        </span>
                        <div>
                          <span>
                            <IconCard brand="none" className={css.cardIcon} />
                            &nbsp;
                            <FormattedMessage id="CheckoutPaymentPage.cardText" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <>
                {formFetching ? (
                  <div>
                    <Spinner /> <span>Please wait,fetching details....</span>
                  </div>
                ) : (
                  renderForm()
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
