import React, { useEffect, useState } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import {
  AspectRatioWrapper,
  Button,
  IconCollection,
  Modal,
  NamedLink,
  ResponsiveImage,
} from '../../components';

import css from './ListingCard.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import AuthenticationForm from '../AuthenticationForm/AuthenticationForm';
import { searchListings } from '../../containers/FavoritesPage/FavoritesPage.duck';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl, isFavoritesPage } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div title={priceTitle}>{formattedPrice}</div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDislikeOpen, setIsDislikeOpen] = useState(false);
  const state = useSelector(state => state);
  const { currentUser } = state.user;
  const { updateInProgress } = state.ProfileSettingsPage;
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    isFavoritesPage,
    isListingPage,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const { size } = publicData || {};
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const isOwnListing = author?.id?.uuid === currentUser?.id?.uuid;
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  // useEffect(() => {
  //   if (currentUser?.id) {
  //     setIsModalOpen(false);
  //   }

  // }, [isModalOpen]);
  const { bagItems = [], likeItems = [] } =
    (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};
  const index = bagItems && bagItems.findIndex(e => e == id);
  const likeIndex = likeItems && likeItems.findIndex(e => e == id);

  const handleDislike = () => {
    setIsDislikeOpen(true);
  };

  const handleBagItems = async () => {
    if (isOwnListing) {
      return null;
    }
    if (!currentUser?.id) {
      setIsModalOpen(true);
    } else {
      if (index > -1) {
        bagItems.splice(index, 1);
      } else {
        bagItems.push(id);
      }
      const profile = {
        protectedData: {
          bagItems,
        },
      };
      !isOwnListing && (await dispatch(updateProfile(profile)));
    }
  };

  const handleLikeItems = async () => {
    if (isOwnListing) {
      return null;
    }
    if (!currentUser?.id) {
      setIsModalOpen(true);
    } else {
      if (likeIndex > -1) {
        likeItems.splice(likeIndex, 1);
      } else {
        alert('Added to favorites');
        likeItems.push(id);
      }
      const profile = {
        protectedData: {
          likeItems,
        },
      };
      !isOwnListing && (await dispatch(updateProfile(profile)));
      setIsDislikeOpen(false);
      dispatch(searchListings(likeItems));
    }
  };

  const loginRequriedModal = (
    <Modal
      id="loginFormRequriedModal"
      isOpen={isModalOpen}
      onClose={e => {
        e.preventDefault();
        setIsModalOpen(false);
      }}
      isLandingPage={false}
      usePortal
      contentClassName={css.modalContent}
      onManageDisableScrolling={() => {}}
    >
      <div>
        <AuthenticationForm isModalOpen={isModalOpen} />
      </div>
    </Modal>
  );

  const dislikeModal = (
    <Modal
      id="loginFormRequriedModal"
      isOpen={isDislikeOpen}
      onClose={e => {
        e.preventDefault();
        setIsDislikeOpen(false);
      }}
      isLandingPage={false}
      usePortal
      className={css.modalRemove}
      onManageDisableScrolling={() => {}}
    >
      <div className={css.modalContent}>
        <svg
          className={css.deleteIcon}
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm-8 5v6h2v-6H9zm4 0v6h2v-6h-2zM9 4v2h6V4H9z"></path>
          </g>
        </svg>
        <h3 className={css.modalTitle}>
          <FormattedMessage id="ListingCard.heading" />
        </h3>
        <p className={css.modalText}>
          <FormattedMessage id="ListingCard.dislikeText" />
        </p>
        <div className={css.buttonGroups}>
          <Button className={css.cancelBtn} onClick={() => setIsDislikeOpen(false)}>
            <FormattedMessage id="ListingCard.cancel" />
          </Button>
          <br />
          <Button
            className={css.removeBtn}
            onClick={e => {
              e.preventDefault();
              handleLikeItems();
            }}
            inProgress={updateInProgress}
          >
            <FormattedMessage id="ListingCard.remove" />
          </Button>
        </div>
      </div>
    </Modal>
  );

  return (
    <>
      <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
        <div className={css.imageWrapper}>
          <AspectRatioWrapper
            className={isListingPage ? css.listingPageRatio : css.aspectRatioWrapper}
            width={aspectWidth}
            height={aspectHeight}
            {...setActivePropsMaybe}
          >
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={variants}
              sizes={renderSizes}
              listingImagesUrl={
                publicData?.listingImagesUrl && publicData?.listingImagesUrl[0]?.url
              }
              likeIndex={likeIndex}
              handleLikeItems={handleLikeItems}
              isListingCard={true}
              handleDislike={handleDislike}
              isFavoritesPage={isFavoritesPage}
              isOwnListing={isOwnListing}
            />
          </AspectRatioWrapper>
          {!isListingPage && currentStock === 0 ? (
            <div className={css.soldText}>
              <FormattedMessage id="ListingCard.soldText" />
            </div>
          ) : null}
        </div>
        <div className={css.info}>
          <div className={classNames(css.mainInfo, isListingPage && css.listingPageMain)}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>

            <div className={css.cardFooter}>
              <div className={css.priceWrapper}>
                <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
                {(size || size == 0) && (
                  <span>
                    <FormattedMessage id="ListingCard.size" values={{ size }} />
                  </span>
                )}
              </div>
              {!isListingPage && (
                <div
                  className={
                    currentStock === 0 || isOwnListing ? css.desktopTextDisable : css.bagButton
                  }
                >
                  {index > -1 ? (
                    <span
                      onClick={e => {
                        e.preventDefault();
                        if (!isOwnListing && currentStock !== 0) {
                          handleBagItems();
                        }
                      }}
                    >
                      <IconCollection icon={'IconBagAdded'} />
                      <span className={css.desktopText}>
                        {' '}
                        <FormattedMessage id="ListingCard.addedToBag" />
                      </span>
                    </span>
                  ) : (
                    <span
                      onClick={e => {
                        e.preventDefault();
                        if (!isOwnListing && currentStock !== 0) {
                          handleBagItems();
                        }
                      }}
                    >
                      <IconCollection icon={'addToBag'} />
                      <span className={css.desktopText}>
                        <FormattedMessage id="ListingCard.addToBag" />
                      </span>
                    </span>
                  )}
                </div>
              )}
            </div>
            {/* {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null} */}
          </div>
        </div>
      </NamedLink>
      {loginRequriedModal}
      {dislikeModal}
    </>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
