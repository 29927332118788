import React, { useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import moment from 'moment';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldPhoneNumberInput,
  IconCollection,
  NamedLink,
  FieldDateInput,
} from '../../../components';

import css from './SignupForm.module.css';
import { LOGIN } from '../../../util/types';
import { createDotsUser, sendVerificationToken } from '../../../util/api';

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        isPasswordVisibleFunction,
        isPasswordVisible,
        handlePasswordVisibilityToggle,
        handleSignupLoginForm,
        values,
        form,
      } = fieldRenderProps;

      const [openVerifyModal, setOpenVerifyModal] = useState(false);
      const [numberExit, setNumberExit] = useState(false);
      const [inProgresss, setInProgresss] = useState(false);
      const [verifyError, setVerifyError] = useState(null);
      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      const phoneNumberValid = validators.phoneNumberFormatValid(
        intl.formatMessage({
          id: 'SignupForm.phoneNumberValid',
        })
      );

      // phoneNumber
      const phoneNumberRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.phoneNumberRequired',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress || inProgresss;

      const IconPassword = isPasswordVisible ? (
        <div className={css.text} onClick={handlePasswordVisibilityToggle}>
          <IconCollection icon="passwordShowIcon" />
        </div>
      ) : (
        <div className={css.text} onClick={handlePasswordVisibilityToggle}>
          <IconCollection icon="passwordHideIcon" />
        </div>
      );

      const privacyLink = (
        <NamedLink name="PrivacyPolicyPage">
          <FormattedMessage id="AuthenticationPage.termsAndConditionsPrivacyLinkText" />
        </NamedLink>
      );

      const termsLink = (
        <NamedLink name="TermsOfServicePage">
          <FormattedMessage id="AuthenticationPage.termsAndConditionsTermsLinkText" />
        </NamedLink>
      );

      const today = moment();
      const oneYearFromToday = moment().add(1, 'year');

      // Function to check if a date is outside the allowed range
      const isOutsideRange = day => day.isBefore(today, 'day') || day.isAfter(oneYearFromToday, 'day');

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {openVerifyModal ? (
            <div>
              <h1 className={css.title}>
                <FormattedMessage id="SignupForm.heading" />
              </h1>
              <h3 className={css.Subtitle}>
                <FormattedMessage id="SignupForm.heading1" />
              </h3>
              <span
                onClick={() => {
                  setOpenVerifyModal(false);
                }}
              >
                Go back
              </span>
              <h5 className={css.signInText}>
                <FormattedMessage id="SignupForm.heading2" />
              </h5>
              <div className={css.formWrapper}>
                <FieldTextInput
                  type="number"
                  id={formId ? `${formId}.otp` : 'otp'}
                  name="otp"
                  autoComplete="otp"
                  label={intl.formatMessage({
                    id: 'SignupForm.otpLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.otpLabel',
                  })}
                // validate={validators.composeValidators(emailRequired, emailValid)}
                />
              </div>
              <div className={css.bottomWrapper}>
                <PrimaryButton
                  className={css.loginBtn}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={!values?.otp || submitInProgress}
                >
                  <FormattedMessage id="SignupForm.signUp" />
                </PrimaryButton>
              </div>
            </div>
          ) : (
            <div>
              <h1 className={css.title}>
                <FormattedMessage id="SignupForm.heading" />
              </h1>
              <h3 className={css.Subtitle}>
                <FormattedMessage id="SignupForm.heading1" />
              </h3>
              <h5 className={css.signInText}>
                <FormattedMessage id="SignupForm.heading2" />
              </h5>
              <div className={css.formWrapper}>
                <FieldTextInput
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.emailLabel',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.emailLabel',
                  })}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <div className={css.name}>
                  <FieldTextInput
                    className={css.firstNameRoot}
                    type="text"
                    id={formId ? `${formId}.fname` : 'fname'}
                    name="fname"
                    autoComplete="given-name"
                    // label={intl.formatMessage({
                    //   id: 'SignupForm.firstNameLabel',
                    // })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.firstNameLabel',
                    })}
                    validate={validators.required(
                      intl.formatMessage({
                        id: 'SignupForm.firstNameRequired',
                      })
                    )}
                  />
                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id={formId ? `${formId}.lname` : 'lname'}
                    name="lname"
                    autoComplete="family-name"
                    // label={intl.formatMessage({
                    //   id: 'SignupForm.lastNameLabel',
                    // })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.lastNameLabel',
                    })}
                    validate={validators.required(
                      intl.formatMessage({
                        id: 'SignupForm.lastNameRequired',
                      })
                    )}
                  />
                </div>
                <div className={css.dateWrapper}>
                  {/* {!values?.weddingDate?.date && <span className={css.placeHolder}>Wedding Date (Optional)</span>} */}
                  <FieldDateInput
                    id="weddingDate"
                    name="weddingDate"
                    className={css.fieldDateInput}
                    label="Wedding Date (Optional)"
                    placeholderText={moment().format('DD-MM-YYYY')}
                    displayFormat="DD-MMM-YYYY"
                    isOutsideRange={isOutsideRange}
                  />
                </div>
                <FieldPhoneNumberInput
                  className={css.password}
                  type="number"
                  id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                  name="phoneNumber"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.passwordLabel',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.phoneNumberLabel',
                  })}
                  validate={validators.composeValidators(phoneNumberRequired, phoneNumberValid)}
                />
                {numberExit && (
                  <span className={css.errorText}>
                    <FormattedMessage id="SignupForm.numberExit" />
                  </span>
                )}
                {verifyError &&  <span className={css.errorText}>{verifyError}</span>}
                <FieldTextInput
                  className={css.password}
                  type={isPasswordVisibleFunction(isPasswordVisible, 'password')}
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.passwordLabel',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.passwordLabel',
                  })}
                  validate={passwordValidators}
                  inputIcon={IconPassword}
                  inputWithImage={css.inputWithImage}
                />

                <FieldTextInput
                  type="refer"
                  className={css.password}
                  id={formId ? `${formId}.refer` : 'refer'}
                  name="refer"
                  autoComplete="refer"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.weddingDateLabel',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.refferingPlaceholder',
                  })}
                // validate={validators.composeValidators(emailRequired, emailValid)}
                />
              </div>

              <div className={css.bottomWrapper}>
                {/* <p className={css.agreeSignUp}>
                <FormattedMessage
                  id="AuthenticationPage.termsAndConditionsAcceptText"
                  values={{ privacyLink, termsLink }}
                />
              </p> */}
                {termsAndConditions}
                <PrimaryButton
                  className={css.loginBtn}
                  type="button"
                  inProgress={inProgresss}
                  disabled={submitDisabled}
                  onClick={() => {
                    setInProgresss(true);
                    const data = {
                      email: values?.email,
                      first_name: values?.fname,
                      last_name: values?.lname,
                      phone_number: values?.phoneNumber,
                    };
                    createDotsUser(data).then(res => {
                      if (res?.isUserPhoneNumberAlreadyExit) {
                        setNumberExit(true);
                        setInProgresss(false);
                        setVerifyError(null);
                      } else {
                        sendVerificationToken({ user_Id: res?.id }).then(() => {
                          setOpenVerifyModal(true);
                          form.change('user_Id', res?.id);
                          setInProgresss(false);
                        });
                      }
                    }).catch(error=>{
                      setInProgresss(false);
                      setVerifyError("Please Enter US Number Only")
                      
                    });
                  }}
                >
                  <FormattedMessage id="SignupForm.signUp" />
                </PrimaryButton>
              </div>
              <p className={css.formloginLInk}>
                <FormattedMessage id="SignupForm.alreadyAccount" />
                <span
                  onClick={() => {
                    handleSignupLoginForm(LOGIN);
                  }}
                  className={css.heighlightText}
                >
                  <FormattedMessage id="SignupForm.login" />
                </span>
              </p>
            </div>
          )}
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
