import React, { useEffect } from 'react';
import { bool, object, string } from 'prop-types';

import { FormattedMessage, intlShape } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import getCountryCodes from '../../translations/countryCodes';
import { FieldTextInput, FieldSelect, FieldLocationAutocompleteInput } from '../../components';
import usStates from 'us-state-codes';
import css from './StripePaymentAddress.module.css';
const identity = v => v;
const StripePaymentAddress = props => {
  const {
    className,
    intl,
    disabled,
    form,
    fieldId,
    card,
    isChecked,
    locale,
    values,
    setIsValue,
    isValue,
  } = props;

  useEffect(() => {
    const hasRequiredValues =
      values &&
      values.billingLocation &&
      values.city &&
      values.state &&
      values.postal &&
      values.country &&
      values.cardHolderFirstName &&
      values.cardHolderLastName;

    if (hasRequiredValues && !isValue) {
      setIsValue(values);
    } else if (!hasRequiredValues && isValue) {
      setIsValue(null);
    }
  }, [values]);

  const optionalText = intl.formatMessage({
    id: 'StripePaymentAddress.optionalText',
  });

  const addressLine1Label = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine1Label',
  });
  const addressLine1Placeholder = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine1Placeholder',
  });
  const addressLine1Required = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.addressLine1Required',
    })
  );

  const addressLine2Label = intl.formatMessage(
    { id: 'StripePaymentAddress.addressLine2Label' },
    { optionalText: optionalText }
  );

  const addressLine2Placeholder = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine2Placeholder',
  });

  const postalCodeLabel = intl.formatMessage({ id: 'StripePaymentAddress.postalCodeLabel' });
  const postalCodePlaceholder = intl.formatMessage({
    id: 'StripePaymentAddress.postalCodePlaceholder',
  });
  const postalCodeRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.postalCodeRequired',
    })
  );

  const cityLabel = intl.formatMessage({ id: 'StripePaymentAddress.cityLabel' });
  const cityPlaceholder = intl.formatMessage({ id: 'ProfileSettingsForm.cityPlaceholder' });
  const cityRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.cityRequired',
    })
  );

  const stateLabel = intl.formatMessage(
    { id: 'StripePaymentAddress.stateLabel' },
    { optionalText: optionalText }
  );
  const statePlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.statePlaceholder' });

  const countryLabel = intl.formatMessage({ id: 'StripePaymentAddress.countryLabel' });
  const countryPlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.countryPlaceholder' });
  const countryRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.countryRequired',
    })
  );

  const handleOnChange = event => {
    const value = event.target.value;
    form.change('postal', value);
    // card.update({ value: { postalCode: value } });
  };

  const addressRequiredMessage = intl.formatMessage({
    id: 'ProfileSettingsForm.addressRequired',
  });

  const addressNotRecognizedMessage = intl.formatMessage({
    id: 'ProfileSettingsForm.addressNotRecognized',
  });

  // Use the language set in config.localization.locale to get the correct translations of the country names
  const countryCodes = getCountryCodes(locale);

  useEffect(() => {
    const parseAddress = address => {
      // Split and trim each part to avoid issues with extra spaces
      const parts = address.split(',').map(part => part.trim());
      return parts.slice(-3); // Get the last three values: City, State Zip, Country
    };

    const extractStateAndZip = stateZip => {
      // Assuming US ZIP codes are 5 digits and potentially followed by a hyphen and 4 digits
      const match = stateZip.match(/(.*?)(\d{5})(-\d{4})?$/);
      if (!match) return {}; // In case the regex does not match

      const [_, stateName, zip] = match;
      return { stateName: stateName.trim(), zip };
    };

    const addressArray = parseAddress(values?.billingLocation?.search || '');

    if (addressArray.length >= 3) {
      // Ensure we have City, State Zip, and Country
      const { stateName, zip } = extractStateAndZip(addressArray[1]);

      form.change('city', addressArray[0]);
      form.change('postal', zip);
      form.change('state', usStates.getStateCodeByStateName(stateName) || stateName);
    }
  }, [values?.billingLocation?.search, form]);

  return (
    <div className={className ? className : css.root}>
      <div className={css.formRow}>
        <FieldTextInput
          id={`cardHolderFirstName`}
          name="cardHolderFirstName"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-line2"
          label={`Card holder's first name`}
          placeholder={'Enter your first name…'}
          onUnmount={() => form.change('cardHolderFirstName', undefined)}
        />
        <FieldTextInput
          id={`cardHolderLastName`}
          name="cardHolderLastName"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-line2"
          label={`Card holder's last name`}
          placeholder={'Enter your last name…'}
          onUnmount={() => form.change('cardHolderLastName', undefined)}
        />
      </div>
      <div className={css.formRow}>
        <FieldLocationAutocompleteInput
          className={css.locationBox}
          rootClassName={css.locationAddress}
          inputClassName={css.locationAutocompleteInput}
          iconClassName={css.locationAutocompleteInputIcon}
          predictionsClassName={css.predictionsRoot}
          validClassName={css.validLocation}
          name="billingLocation"
          label={'Address'}
          placeholder={'Enter address'}
          useDefaultPredictions={false}
          format={identity}
          valueFromForm={values?.billingLocation}
          validate={validators.composeValidators(
            validators.autocompleteSearchRequired(addressRequiredMessage),
            validators.autocompletePlaceSelected(addressNotRecognizedMessage)
          )}
        />
        <FieldTextInput
          id={`${fieldId}.addressLine2`}
          name="addressLine2"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-line2"
          label={'Apartment # • optional'}
          placeholder={addressLine2Placeholder}
          onUnmount={() => form.change('addressLine2', undefined)}
        />
      </div>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.postalCode`}
          name="postal"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing postal-code"
          label={postalCodeLabel}
          placeholder={postalCodePlaceholder}
          validate={postalCodeRequired}
          onUnmount={() => form.change('postal', undefined)}
          onChange={event => handleOnChange(event)}
        />

        <FieldTextInput
          id={`${fieldId}.city`}
          name="city"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-level2"
          label={cityLabel}
          placeholder={cityPlaceholder}
          validate={cityRequired}
          onUnmount={() => form.change('city', undefined)}
        />
      </div>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.state`}
          name="state"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-level1"
          label={stateLabel}
          placeholder={statePlaceholder}
          onUnmount={() => form.change('state', undefined)}
        />

        <FieldSelect
          id={`${fieldId}.country`}
          name="country"
          disabled={disabled}
          className={css.field}
          label={countryLabel}
          validate={countryRequired}
        >
          <option disabled value="">
            {countryPlaceholder}
          </option>
          {countryCodes.map(country => {
            return (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            );
          })}
        </FieldSelect>
      </div>
    </div>
  );
};
StripePaymentAddress.defaultProps = {
  country: null,
  disabled: false,
  fieldId: null,
};

StripePaymentAddress.propTypes = {
  country: string,
  disabled: bool,
  form: object.isRequired,
  fieldId: string,
  locale: string.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default StripePaymentAddress;
