import React, { useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import IconSearchDesktop from '../../containers/TopbarContainer/Topbar/TopbarSearchForm/IconSearchDesktop';

const indexName = process.env.REACT_APP_ALGOLIA_LISTING_INDEX;

const AlgoliaAutocomplete = props => {
  const history = useHistory();
  const routes = useRouteConfiguration();

  const { className, currentSearchParams } = props;
  const [searchTerm, setsearchTerm] = useState(
    currentSearchParams?.searchState?.query ||
    currentSearchParams?.searchState[indexName]?.query ||
    ''
  );

  const onSubmit = () => {
    typeof window !== 'undefined' && sessionStorage.removeItem('category')
    history.push(
      searchTerm ? createResourceLocatorString('AlgoliaSearchPage', routes, {}, { query: searchTerm }) : createResourceLocatorString('AlgoliaSearchPage', routes, {},{})
    );
  };

  const handleInputChange = e => {
    const value = e.currentTarget.value;
    setsearchTerm(value);
  };

  const handleIconClick = e => {
    if (searchTerm.trim()) {
      onSubmit();
    }
  };
  return (
    <>
      <input
        value={searchTerm}
        type="text"
        className={className}
        name="keywords"
        placeholder="Search..."
        onChange={handleInputChange}
        onKeyUpCapture={e => {
          if (e.key === 'Enter') {
            onSubmit();
          }
        }}
      />
      <button onClick={handleIconClick}>
        <IconSearchDesktop />
      </button>
    </>
  );
};

export default withRouter(AlgoliaAutocomplete);
