import pick from 'lodash/pick';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { initializeOnboarding, retrieveAndUpdateFlowData } from '../../util/api';
import { getUserDetails } from '../../util/dataExtractor';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/DotsPayoutPage/SET_INITIAL_VALUES';
export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/DotsPayoutPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/DotsPayoutPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/DotsPayoutPage/SAVE_PAYOUT_DETAILS_ERROR';

export const FLOW_DATA_REQUEST = 'app/DotsPayoutPage/FLOW_DATA_REQUEST';
export const FLOW_DATA_SUCCESS = 'app/DotsPayoutPage/FLOW_DATA_SUCCESS';
export const FLOW_DATA_ERROR = 'app/DotsPayoutPage/FLOW_DATA_ERROR';

// ================ Reducer ================ //

const initialState = {
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  fromReturnURL: false,
  flowData: {},
  flowDataInProgress: false,
  flowDataError: null

};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };
    case FLOW_DATA_REQUEST:
      return {
        ...state, flowDataInProgress: true,
        flowDataError: null
      };
    case FLOW_DATA_SUCCESS:
      return {
        ...state,
        flowData: payload,
        flowDataInProgress: false,
        flowDataError: null
      };
    case FLOW_DATA_ERROR:
      return {
        ...state,
        flowDataInProgress: false,
        flowDataError: payload
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const savePayoutDetailsRequest = () => ({
  type: SAVE_PAYOUT_DETAILS_REQUEST,
});
export const savePayoutDetailsError = () => ({
  type: SAVE_PAYOUT_DETAILS_ERROR,
});
export const savePayoutDetailsSuccess = () => ({
  type: SAVE_PAYOUT_DETAILS_SUCCESS,
});
export const flowDataRequest = () => ({
  type: FLOW_DATA_REQUEST,
});
export const flowDataSuccess = (data) => ({
  type: FLOW_DATA_SUCCESS,
  payload: data
});
export const flowDataError = (e) => ({
  type: FLOW_DATA_ERROR,
  payload: e,
  error: true

});

// ================ Thunks ================ //

export const savePayoutDetails = (props) => async (dispatch, getState, sdk) => {
  try {

    const { user_id } = props;
    // const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
    dispatch(savePayoutDetailsRequest());

    // const response = await dispatch(upsertThunk(values, { expand: true }))
    const { link } = await initializeOnboarding({ user_id })
    typeof window !== 'undefined' && window.open(link, '_blank'); // Open link in a new tab
    dispatch(savePayoutDetailsSuccess());
    return response;

  } catch (error) {

    dispatch(savePayoutDetailsError(error));
  }
};

export const retrieveFlowData = (params) => async (dispatch) => {
  dispatch(flowDataRequest())
  try {
    const response = await retrieveAndUpdateFlowData({ ...params })
    dispatch(flowDataSuccess(response))
  } catch (error) {
    dispatch(flowDataError(storableError(error)));

  }
}
export const loadData = () => async (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialValues());
  const currentUser = await dispatch(fetchCurrentUser());
  const { dotsEncryptedData = {} } = currentUser?.attributes?.profile?.privateData || {};
  const { user_id } = dotsEncryptedData || {};
  const currentUserId = currentUser?.id && getUserDetails(currentUser)?.id;
  if (dotsEncryptedData?.encryptedFlowData) {
    dispatch(retrieveFlowData({ ...dotsEncryptedData, dotsUserId: user_id, currentUserId }))
  }
};
