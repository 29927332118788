import image1 from '../assets/categoryIcons/image1.png';
import image2 from '../assets/categoryIcons/image2.png';
import image3 from '../assets/categoryIcons/image3.png';
import image4 from '../assets/categoryIcons/image4.png';
//////////////////////////////////////////////////////////////////////
// Configurations related to search                                 //
// Note: some search experience is also on defaultMapsConfig        //
// and defaultListingConfig.js is responsible public data filtering //
//////////////////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

// Main search used in Topbar.
// This can be either 'keywords' or 'location'.
// Note: The mainSearch comes from the listing-search asset nowadays by default.
//       To use this built-in configuration, you need to remove the overwrite from configHelper.js (mergeSearchConfig func)
export const mainSearch = {
  searchType: 'keywords',
};

export const listingProductType = [
  { key: 'gowns', label: 'Gowns', image: image1, option: 'gowns' },
  { key: 'accessories', label: 'Accessories', image: image2, option: 'accessories' },
  { key: 'occasions', label: 'Occasions', image: image3, option: 'occasions' },
  { key: 'shoes', label: 'Shoes', image: image4, option: 'shoes' },
];

export const listingCategory = [
  { key: 'bridal-gowns', label: 'Bridal Gowns', parentKey: 'gowns', option: 'bridal-gowns' },
  
  { key: 'clutch', label: 'Clutch', parentKey: 'accessories', option: 'clutch' },
  { key: 'belt', label: 'Belt', parentKey: 'accessories', option: 'belt' },
  { key: 'hair-accessories', label: 'Hair Accessories', parentKey: 'accessories', option: 'hair-accessories' },
  { key: 'earrings', label: 'Earrings', parentKey: 'accessories', option: 'earrings' },
  { key: 'necklace', label: 'Necklace', parentKey: 'accessories', option: 'necklace' },
  { key: 'bracelet', label: 'Bracelet', parentKey: 'accessories', option: 'bracelet' },
  { key: 'veil', label: 'Veil', parentKey: 'accessories', option: 'veil' },
  { key: 'shawl', label: 'Shawl', parentKey: 'accessories', option: 'shawl' },
  
  { key: 'bachelorette-party', label: 'Bachelorette Party', parentKey: 'occasions', option: 'bachelorette-party' },
  { key: 'bridal-shower', label: 'Bridal Shower', parentKey: 'occasions', option: 'bridal-shower' },
  { key: 'engagement-party', label: 'Engagement Party', parentKey: 'occasions', option: 'engagement-party' },
  { key: 'engagement-photos', label: 'Engagement Photos', parentKey: 'occasions', option: 'engagement-photos' },
  { key: 'honeymoon', label: 'Honeymoon', parentKey: 'occasions', option: 'honeymoon' },
  { key: 'rehearsal-dinner', label: 'Rehearsal Dinner', parentKey: 'occasions', option: 'rehearsal-dinner' },
  { key: 'reception-cocktail-dress', label: 'Reception/Cocktail dress', parentKey: 'occasions', option: 'reception-cocktail-dress' },
  
  { key: 'other', label: 'Other', parentKey: 'accessories', option: 'other' },
  { key: 'other', label: 'Other', parentKey: 'gowns', option: 'other' },
  { key: 'other', label: 'Other', parentKey: 'occasions', option: 'other' },
];

export const listingSize = [
  { key: '00', label: '00', parentKey: ['gowns', 'occasions'], option: '00' },
  { key: '0', label: '0', parentKey: ['gowns', 'occasions'], option: '0' },
  { key: '2', label: '2', parentKey: ['gowns', 'occasions','shoes'], option: '2' },
  { key: '4', label: '4', parentKey: ['gowns', 'occasions'], option: '4' },
  { key: '6', label: '6', parentKey: ['gowns', 'occasions'], option: '6' },
  { key: '8', label: '8', parentKey: ['gowns', 'occasions'], option: '8' },
  { key: '10', label: '10', parentKey: ['gowns', 'occasions'], option: '10' },
  { key: '12', label: '12', parentKey: ['gowns', 'occasions'], option: '12' },
  { key: '14', label: '14', parentKey: ['gowns', 'occasions'], option: '14' },
  { key: '16', label: '16', parentKey: ['gowns', 'occasions'], option: '16' },
  { key: '18', label: '18', parentKey: ['gowns', 'occasions'], option: '18' },
  { key: '20', label: '20', parentKey: ['gowns', 'occasions'], option: '20' },

  // { key: '2', label: '2', parentKey: ['shoes'], option: '2' },
  { key: '3', label: '3', parentKey: ['shoes'], option: '3' },
  { key: '4', label: '4', parentKey: ['shoes'], option: '4' },
  { key: '4.5', label: '4.5', parentKey: ['shoes'], option: '4.5' },
  { key: '5', label: '5', parentKey: ['shoes'], option: '5' },
  { key: '5.5', label: '5.5', parentKey: ['shoes'], option: '5.5' },
  { key: '6', label: '6', parentKey: ['shoes'], option: '6' },
  { key: '6.5', label: '6.5', parentKey: ['shoes'], option: '6.5' },
  { key: '7', label: '7', parentKey: ['shoes'], option: '7' },
  { key: '7.5', label: '7.5', parentKey: ['shoes'], option: '7.5' },
  { key: '8', label: '8', parentKey: ['shoes'], option: '8' },
  { key: '8.5', label: '8.5', parentKey: ['shoes'], option: '8.5' },
  { key: '9', label: '9', parentKey: ['shoes'], option: '9' },
  { key: '9.5', label: '9.5', parentKey: ['shoes'], option: '9.5' },
  { key: '10', label: '10', parentKey: ['shoes'], option: '10' },
  { key: '10.5', label: '10.5', parentKey: ['shoes'], option: '10.5' },
  { key: '11', label: '11', parentKey: ['shoes'], option: '11' },
  { key: '11.5', label: '11.5', parentKey: ['shoes'], option: '11.5' },
  { key: '12', label: '12', parentKey: ['shoes'], option: '12' },
  { key: '12.5', label: '12.5', parentKey: ['shoes'], option: '12.5' },
  { key: '13', label: '13', parentKey: ['shoes'], option: '13' },
  { key: '13.5', label: '13.5', parentKey: ['shoes'], option: '13.5' },
  { key: '14', label: '14', parentKey: ['shoes'], option: '14' },
  { key: '16', label: '16', parentKey: ['shoes'], option: '16' },
  { key: '18', label: '18', parentKey: ['shoes'], option: '18' },
  { key: '20', label: '20', parentKey: ['shoes'], option: '20' },

  { key: 'other', label: 'Other', parentKey: ['gowns', 'occasions'], option: 'other' },
  { key: 'other', label: 'Other', parentKey: ['shoes'], option: 'other' },
];

export const listingStreetSize = [
  { key: '00', label: '00', parentKey: 'gowns', option: '00' },
  { key: '0', label: '0', parentKey: 'gowns', option: '0' },
  { key: '2', label: '2', parentKey: 'gowns', option: '2' },
  { key: '4', label: '4', parentKey: 'gowns', option: '4' },
  { key: '6', label: '6', parentKey: 'gowns', option: '6' },
  { key: '8', label: '8', parentKey: 'gowns', option: '8' },
  { key: '10', label: '10', parentKey: 'gowns', option: '10' },
  { key: '12', label: '12', parentKey: 'gowns', option: '12' },
  { key: '14', label: '14', parentKey: 'gowns', option: '14' },
  { key: '16', label: '16', parentKey: 'gowns', option: '16' },
  { key: '18', label: '18', parentKey: 'gowns', option: '18' },
  { key: '20', label: '20', parentKey: 'gowns', option: '20' },
  { key: 'other', label: 'Other', parentKey: 'gowns', option: 'other' },
];

export const listingHeelHeight = [
  { key: '0-inches', label: '0 inches', option: '0-inches' },
  { key: '1-2-inches', label: '1-2 inches', option: '1-2-inches' },
  { key: '2-3-inches', label: '2-3 inches', option: '2-3-inches' },
  { key: '4+-inches', label: '4+ inches', option: '4+-inches' },
  { key: 'other', label: 'Other', option: 'other' },
];

export const listingMaterial = [
  { key: 'brocade', label: 'Brocade', parentKey: 'gowns', option: 'brocade' },
  { key: 'charmeuse', label: 'Charmeuse', parentKey: 'gowns', option: 'charmeuse' },
  { key: 'chiffon', label: 'Chiffon', parentKey: 'gowns', option: 'chiffon' },
  { key: 'cotton', label: 'Cotton', parentKey: 'gowns', option: 'cotton' },
  { key: 'crepe', label: 'Crepe', parentKey: 'gowns', option: 'crepe' },
  { key: 'duchesse', label: 'Duchesse', parentKey: 'gowns', option: 'duchesse' },
  { key: 'georgette', label: 'Georgette', parentKey: 'gowns', option: 'georgette' },
  { key: 'lace', label: 'Lace', parentKey: 'gowns', option: 'lace' },
  { key: 'organza', label: 'Organza', parentKey: 'gowns', option: 'organza' },
  { key: 'ployester', label: 'Ployester', parentKey: 'gowns', option: 'ployester' },
  { key: 'satin', label: 'Satin', parentKey: 'gowns', option: 'satin' },
  { key: 'silk', label: 'Silk', parentKey: 'gowns', option: 'silk' },
  { key: 'silk-dupioni', label: 'Silk Dupioni', parentKey: 'gowns', option: 'silk-dupioni' },
  { key: 'taffetta', label: 'Taffetta', parentKey: 'gowns', option: 'taffetta' },
  { key: 'tulle', label: 'Tulle', parentKey: 'gowns', option: 'tulle' },
  { key: 'velvet', label: 'Velvet', parentKey: 'gowns', option: 'velvet' },
  { key: 'wool', label: 'Wool', parentKey: 'gowns', option: 'wool' },
  { key: 'other', label: 'Other', parentKey: 'gowns', option: 'other' },
];

export const listingColor = [
  { key: 'white', label: 'White', parentKey: ['gowns', 'occasions', 'accessories', 'shoes'], option: "white" },
  { key: 'ivory', label: 'Ivory', parentKey: ['gowns', 'occasions', 'accessories', 'shoes'], option: "ivory" },
  { key: 'silver', label: 'Silver', parentKey: ['gowns', 'occasions', 'accessories', 'shoes'], option: "silver" },
  { key: 'gold', label: 'Gold', parentKey: ['shoes'], option: "gold" },
  { key: 'tan', label: 'Tan', parentKey: ['shoes'], option: "tan" },
  { key: 'brown', label: 'Brown', parentKey: ['shoes'], option: "brown" },
  { key: 'clear', label: 'Clear', parentKey: ['shoes'], option: "clear" },
  {
    key: 'multi-color',
    label: 'Multi-Color',
    parentKey: ['gowns', 'occasions', 'accessories', 'shoes']
    , option: "multi-color"
  },
  { key: 'other', label: 'Other', parentKey: ['gowns', 'occasions', 'accessories', 'shoes'], option: "other" },
];

export const listingStyle = [
  { key: 'beach', label: 'Beach', parentKey: 'gowns', option: "beach" },
  { key: 'boho', label: 'Boho', parentKey: 'gowns', option: "boho" },
  { key: 'casual', label: 'Casual', parentKey: 'gowns', option: "casual" },
  { key: 'embellished', label: 'Embellished', parentKey: 'gowns', option: "embellished" },
  { key: 'formal', label: 'Formal', parentKey: 'gowns', option: "formal" },
  { key: 'fitted', label: 'Fitted', parentKey: 'gowns', option: "fitted" },
  { key: 'lace', label: 'Lace', parentKey: 'gowns', option: "lace" },
  { key: 'modern', label: 'Modern', parentKey: 'gowns', option: "modern" },
  { key: 'princess', label: 'Princess', parentKey: 'gowns', option: "princess" },

  { key: 'block-heel', label: 'Block Heel', parentKey: 'shoes', option: "block-heel" },
  { key: 'stiletto', label: 'Stiletto', parentKey: 'shoes', option: "stiletto" },
  { key: 'open-toe', label: 'Open Toe', parentKey: 'shoes', option: "open-toe" },
  { key: 'closed-toe', label: 'Closed Toe', parentKey: 'shoes', option: "closed-toe" },
  { key: 'platform', label: 'Platform', parentKey: 'shoes', option: "platform" },
  { key: 'mule', label: 'Mule', parentKey: 'shoes', option: "mule" },
  { key: 'sneaker', label: 'Sneaker', parentKey: 'shoes', option: "sneaker" },

  { key: 'other', label: 'Other', parentKey: 'gowns', option: "other" },
  { key: 'other', label: 'Other', parentKey: 'shoes', option: "other" },
];

export const allowNegotiation = [{ key: 'allowed', label: 'Yes' }, { key: 'no', label: 'No' }];

export const originalOwners = [{ key: 'yes', label: 'Yes' }, { key: 'no', label: 'No' }];

export const originalReceipts = [{ key: 'yes', label: 'Yes' }, { key: 'no', label: 'No' }];

export const boxSize = [
  { key: 'small-flat-rate', label: 'Small Flat Rate Box (8.6875 x 5.4375 x 1.75 in)', length: 8.6875, width: 5.4375, height: 1.75, },
  { key: 'medium-flat-rate', label: 'Medium Flat Rate Box 1 (11.25 x 8.75 x 6 in)', length: 11.25, width: 8.75, height: 6, },
  { key: 'large-flat-rate', label: 'Large Flat Rate Box (12.25 x 12.25 x 6 in)', length: 12.25, width: 12.25, height: 6, },
  { key: 'regional-rate', label: 'Regional Rate Box A1 (10.125 x 7.125 x 5 in)', length: 10.125, width: 7.125, height: 5, },
];

/**
 * Configuration for default filters.
 * These are custom configs for each filter.
 * Common properties: key, schemaType, and label.
 * Note: the order of default filters is defined in util/configHelpers.js
 * To use this built-in configuration, you need to remove the overwrite from configHelper.js (mergeSearchConfig func)
 */

export const dateRangeFilter = {
  schemaType: 'dates',
  // Should the entire date range be available, or just part of it
  // Note: Since we don't enforce location search for dates filtering,
  //       we don't use API's 'time-full' in actual queries. It would require time zone info.
  availability: 'time-full', // time-partial
  // Options: day/night. This affects counting and whether single day picking is possible.
  dateRangeMode: 'day',
};

/**
 * Note: the order of default filters is defined in util/configHelpers.js
 * To use this built-in configuration, you need to remove the overwrite from configHelper.js (mergeSearchConfig func)
 */
export const priceFilter = {
  schemaType: 'price',
  // Note: unlike most prices this is not handled in subunits
  min: 0,
  max: 1000,
  step: 5,
};

// // This is not in use by default.
// export const keywordsFilter = {
//   key: 'keywords',
//   schemaType: 'keywords',
// }


export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Marketplace API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keywords' to this list.
  conflictingFilters: [],

  options: [
    // These are default sort options
    { key: 'createdAt', labelTranslationKey: 'SortBy.newest' },
    // { key: '-createdAt', labelTranslationKey: 'SortBy.oldest' },
    { key: '-price', labelTranslationKey: 'SortBy.lowestPrice' },
    { key: 'price', labelTranslationKey: 'SortBy.highestPrice' },
    // If you add own sort options, you can also use label key: { key: 'meta_rating', label: 'Highest rated' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    // {
    //   key: 'relevance',
    //   labelTranslationKey: 'SortBy.relevance',
    //   labelTranslationKeyLong: 'SortBy.relevanceLong',
    // },
  ],
};
